import {
  Box, Grid,
} from '@mui/material';
import React from 'react';
import DraftsIcon from '@mui/icons-material/Drafts';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BenefitCard from '../components/BenefitCard';

export default function Benefits() {
  return (
    <Box sx={{
      // padding: '10px',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#242424',
      marginTop: '-1px',
    }}
    >
      <Box sx={{ maxWidth: 1000, textAlign: 'center' }}>
        <Grid container sx={{ padding: '20px', paddingTop: 0 }} spacing={1} mt={2}>
          <Grid item xs={4}>
            <BenefitCard
              renderIcon={(props) => <DraftsIcon {...props} />}
              title="Acesso imediato pelo seu e-mail."
            />
          </Grid>
          <Grid item xs={4}>
            <BenefitCard
              renderIcon={(props) => <WorkspacePremiumIcon {...props} />}
              title="Garantia absoluta de 7 dias."
            />
          </Grid>
          <Grid item xs={4}>
            <BenefitCard
              renderIcon={(props) => <FileCopyIcon {...props} />}
              title="Acesso às minhas mensagens de prospecção."
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
