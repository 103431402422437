import {
  FormControlLabel,
  FormGroup,
  Grid, ImageList, ImageListItem, Typography, createStyles, styled, useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { Check } from '@mui/icons-material';
import stmt1 from '../assets/statements/stmt1.jpeg';
import stmt2 from '../assets/statements/stmt2.png';
import stmt3 from '../assets/statements/stmt3.jpeg';
import stmt4 from '../assets/statements/stmt4.png';
import stmt5 from '../assets/statements/stmt5.jpeg';
import stmt6 from '../assets/statements/stmt6.jpeg';
import stmt7 from '../assets/statements/stmt7.jpeg';
import stmt8 from '../assets/statements/stmt8.jpeg';
import { Emphasis } from '../components/Emphasis';
import { PurchaseButton } from '../components/PurchaseButton';

const useStyles = (isMobile) => createStyles({
  background: {
    width: '100vw',
    padding: isMobile ? '20px' : '20px 100px',
  },
});

export default function Statements() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles(isMobile);
  const images = [stmt1, stmt2, stmt3, stmt4, stmt5, stmt6, stmt7, stmt8];

  const Divider = styled('div')({
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    margin: '70px 0',
    width: '100%',
  });

  return (
    <Grid container justifyContent="center" style={styles.background} flexDirection="column" alignItems="center">
      <Typography mt={5} mb={2} variant="h4" textAlign="center">
        Veja os
        <Emphasis> resultados </Emphasis>
        dos alunos que seguiram o
        <Emphasis> Método Ártemis </Emphasis>
        de prospecção:
      </Typography>
      <ImageList variant="masonry" cols={isMobile ? 1 : 3} gap={isMobile ? 20 : 15} sx={{ padding: isMobile ? 2 : '20px 0px', maxWidth: 1400 }}>
        {
          images.map((img, i) => (
            <ImageListItem key={img} style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 10px', borderRadius: '10px' }}>
              <img src={img} style={{ maxHeight: '100%', maxWidth: 'auto', objectFit: 'contain' }} alt={`prova ${i}`} loading="lazy" />
            </ImageListItem>
          ))
        }
      </ImageList>
      <FormGroup sx={{ textAlign: 'left', alignSelf: 'center', padding: '10px 40px' }}>
        <FormControlLabel
          control={<Check sx={{ mr: 2, color: 'green' }} />}
          sx={{ pb: 3 }}
          label={(
            <Typography fontSize={isMobile ? '14pt' : '18pt'} fontWeight="bold">
              Sem investir um real em tráfego pago
            </Typography>
          )}
        />
        <FormControlLabel
          control={<Check sx={{ mr: 2, color: 'green' }} />}
          sx={{ pb: 3 }}
          label={(
            <Typography fontSize={isMobile ? '14pt' : '18pt'} fontWeight="bold">
              Sem apresentar portfólio
            </Typography>
          )}
        />
        <FormControlLabel
          control={<Check sx={{ mr: 2, color: 'green' }} />}
          sx={{ pb: 3 }}
          label={(
            <Typography fontSize={isMobile ? '14pt' : '18pt'} fontWeight="bold">
              Sem ter atuado em projetos de 6, 7 ou 8 dígitos
            </Typography>
          )}
        />
        <FormControlLabel
          control={<Check sx={{ mr: 2, color: 'green' }} />}
          sx={{ pb: 3 }}
          label={(
            <Typography fontSize={isMobile ? '14pt' : '18pt'} fontWeight="bold">
              Com poucos seguidores nas redes sociais
            </Typography>
          )}
        />
      </FormGroup>
      <PurchaseButton text="Quero ter esses resultados" style={{ marginTop: 2 }} />
      <Divider />
    </Grid>
  );
}
