import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Emphasis } from '../components/Emphasis';
import { PurchaseButton } from '../components/PurchaseButton';

export default function CheckList() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        marginTop: isMobile ? 5 : 0,
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
      }}
      pt={5}
      pl={3}
      pr={3}
    >
      <Box sx={{ maxWidth: 800 }}>
        <Typography variant="h4" fontWeight="bold" mb={5}>
          <Emphasis>Perguntas Frequentes </Emphasis>
        </Typography>
        <Accordion sx={{ textAlign: 'left' }} style={{ backgroundColor: theme.palette.secondary.main }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography color="primary" fontSize="14pt">Estou começando agora, o Projeto Prospecção Ártemis é pra mim?</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ backgroundColor: theme.palette.primary.light, paddingTop: 20 }}
          >
            <Typography>
              Sim. Esse projeto foi feito para ajudar copywriters e profissionais do digital que
              já atuam na área ou pretendem iniciar e não querem perder tempo prospectando o
              cliente errado.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ textAlign: 'left' }} style={{ backgroundColor: theme.palette.secondary.main }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography color="primary" fontSize="14pt">Quando terei acesso ao Projeto Prospecção Ártemis?</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ backgroundColor: theme.palette.primary.light, paddingTop: 20 }}
          >
            <Typography>
              Assim que efetuar o pagamento, você receberá no seu e-mail as informações
              para acessar a área de membros da Kiwify.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ textAlign: 'left' }} style={{ backgroundColor: theme.palette.secondary.main }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography color="primary" fontSize="14pt">Quais são as formas de pagamento?</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ backgroundColor: theme.palette.primary.light, paddingTop: 20 }}
          >
            <Typography>
              Você poderá parcelar em até 12x utilizando até dois cartões de crédito,
              pagar no boleto ou via pix.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ textAlign: 'left' }} style={{ backgroundColor: theme.palette.secondary.main }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography color="primary" fontSize="14pt">Nunca prospectei nenhum cliente, esse curso vai me ajudar?</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ backgroundColor: theme.palette.primary.light, paddingTop: 20 }}
          >
            <Typography>
              Sim. Todas as aulas vão te mostrar o passo a passo para você
              prospectar e fechar com o seu primeiro cliente.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ textAlign: 'left' }} style={{ backgroundColor: theme.palette.secondary.main }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography color="primary" fontSize="14pt">Por quanto tempo terei acesso ao curso?</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ backgroundColor: theme.palette.primary.light, paddingTop: 20 }}
          >
            <Typography>
              Você terá 1 (um) ano de acesso ao Projeto Prospecção Ártemis para assistir
              todas as aulas,
              aproveitar os bônus e a comunidade exclusiva.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ textAlign: 'left' }} style={{ backgroundColor: theme.palette.secondary.main }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography color="primary" fontSize="14pt">
              Como é devolvido o valor pago em caso de cancelamento?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ backgroundColor: theme.palette.primary.light, paddingTop: 20 }}
          >
            <Typography>
              A partir do momento que você entrar no Projeto Prospecção Ártemis, você tem 7 dias de
              degustação gratuita. Se por qualquer motivo você não quiser mais encontrar clientes de
              alto valor dentro desse prazo, basta enviar um email e devolverei 100% do seu
              investimento.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography variant="h4" mt={5}>Sua pergunta não foi respondida?</Typography>
        <Typography variant="h6" mt={3}>Entre em contato com o meu time:</Typography>
        <PurchaseButton text="Tirar minhas dúvidas" link="https://wa.me/5511973992122?text=Ol%C3%A1%2C%20quero%20ser%20aluno%20da%20%C3%81rtemis%2C%20mas%20fiquei%20com%20uma%20d%C3%BAvida...%20" />
      </Box>
    </Box>
  );
}
