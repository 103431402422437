import {
  Grid, Typography, createStyles, useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import bgDesktop from '../assets/bg_desktop.png';
import bgMobile from '../assets/bg_mobile.png';
import { PurchaseButton } from '../components/PurchaseButton';
import { Emphasis } from '../components/Emphasis';

const useStyles = (isMobile) => createStyles({
  background: {
    width: '100vw',
    backgroundImage: `url(${isMobile ? bgMobile : bgDesktop})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: isMobile ? '90vh' : '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: `${isMobile ? 'center' : 'flex-start'}`,
    padding: isMobile ? '35vh 40px 10vh 40px' : '0 20px',
  },
});

export default function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles(isMobile);

  return (
    <Grid
      container
      sx={styles.background}
    >
      <Grid container item flexDirection="column" alignItems="center" maxWidth={isMobile ? '100vw' : '50vw'}>
        <Emphasis
          style={{
            fontWeight: 'bold', textAlign: 'center', maxWidth: 600, fontSize: isMobile ? '22pt' : '28pt',
          }}
        >
          Pare de ser ignorado no direct
        </Emphasis>
        <Typography color={theme.palette.primary.contrastText} sx={{ textAlign: 'center', maxWidth: 600, fontSize: '16pt' }}>
          Encontre clientes dispostos a pagar um
          <Emphasis> valor justo </Emphasis>
          pelo seu trabalho e feche
          <Emphasis> contratos </Emphasis>
          que vão garantir a sua
          <Emphasis> previsibilidade financeira.</Emphasis>
        </Typography>
        <PurchaseButton />
      </Grid>
    </Grid>
  );
}
