import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Box, Container, CssBaseline,
} from '@mui/material';
import Header from './sections/Header';
import FeaturedContent from './sections/FeaturedContent';
import CurrentReality from './sections/CurrentReality';
import WhoIsShe from './sections/WhoIsShe';
import FAQ from './sections/FAQ';
import { Footer } from './sections/Footer';
import Benefits from './sections/Benefits';
import Statements from './sections/Statements';

const primary = '#F2F2F2';
const secondary = '#a67c00';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Lora',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: primary,
      contrastText: '#141414',
      dark: '#e0cebf',
    },
    secondary: {
      main: secondary,
    },
    success: {
      main: '#CD9A72',
    },
    background: {
      default: primary,
      paper: '#F2F2F2',
    },
    text: {
      primary: '#1b1b1b',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl" style={{ height: '100vh', padding: 0, margin: 0 }}>
        <Box>
          <Header />
          <Benefits />
          <CurrentReality />
          <Statements />
          <FeaturedContent />
          <WhoIsShe />
          <FAQ />
          <Footer />
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
