/* eslint-disable no-nested-ternary */
import {
  Box,
  Grid, Typography, createStyles, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';
import bg from '../assets/who-is-she.png';
import { Emphasis } from '../components/Emphasis';
import { PurchaseButton } from '../components/PurchaseButton';

const useStyles = (isMobile) => createStyles({
  background: {
    width: '100vw',
    maxWidth: '100vw',
    backgroundColor: '#F3F3F3',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: isMobile ? 'cover' : 'contain',
    backgroundPosition: isMobile ? 'center' : 'left',
    minHeight: '120vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: isMobile ? '0 40px 10vh 40px' : '0 20px',
    maxHeight: '90vh',
  },
});

function BlaBlaBla({ isSmall }) {
  return (
    <Box pl={isSmall ? 30 : 0}>
      <Typography fontSize="17pt" mt={5}>
        Prazer!
        <Emphasis> Letícia Sassati. </Emphasis>
        Sou Mentora, empresária e copywriter.
      </Typography>
      <Typography fontSize="17pt" mt={2}>
        Comecei minha trajetória com menos de 200 seguidores nas redes sociais
        e hoje já atuei nos bastidores de mais de 120 projetos, incluindo grandes
        players, como Camila Farani.
      </Typography>
      <Typography fontSize="17pt" mt={4}>
        Depois de experiências frustrantes com clientes que pediam descontos e várias
        revisões, criei o método Ártemis de Prospecção, que me trouxe clientes de alto
        valor que não reclamam do meu preço.
      </Typography>
      <Typography fontSize="17pt" mt={4} mb={5}>
        Como mentora, ajudei mais de 100 copywriters a estruturarem seus negócios,
        encontrarem o cliente ideal e alcançarem uma renda estável. Agora, meu objetivo
        é te ajudar a alcançar os mesmos resultados.
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        <PurchaseButton />
      </Box>
    </Box>
  );
}

function getTextWidth(isLarge, isMedium, isSmall) {
  if (isLarge) return '65vw';
  if (isMedium) return '60vw';
  if (isSmall) return '40vw';
  return '90vw';
}

export default function WhoIsShe() {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const isLarge = useMediaQuery(breakpoints.up(1350));
  const isMedium = useMediaQuery(breakpoints.between(980, 1350));
  const isSmall = useMediaQuery(breakpoints.down(980));
  const styles = useStyles(isMobile);

  const textColor = palette.secondary.main;

  const width = getTextWidth(isLarge, isMedium, isSmall);

  return (
    <Box>
      <Grid
        container
        sx={styles.background}
      >
        <Typography variant="h4" textAlign="center" alignSelf="center" sx={{ marginTop: isMobile || isMedium ? -105 : 0 }}>
          <Emphasis style={{ color: textColor, fontWeight: 'bold' }}>Por que você deveria confiar em mim?</Emphasis>
        </Typography>
        <Box sx={{
          padding: '0 20px 0px 20px',
          color: palette.primary.contrastText,
        }}
        >
          <Box sx={{ maxWidth: width }}>
            {(!isMedium && !isMobile) && <BlaBlaBla isSmall={isSmall} />}
          </Box>
        </Box>
      </Grid>
      {(isMedium || isMobile) && (
        <Box sx={{ marginTop: -25, padding: 5, textAlign: 'center' }}>
          <BlaBlaBla />
        </Box>
      )}
      {/* <Box textAlign="center">
        <PurchaseButton style={{ marginTop }} />
      </Box> */}
    </Box>
  );
}
