import {
  Box, Grid, Typography, darken, styled, useTheme,
} from '@mui/material';
import React from 'react';
import { Emphasis } from '../components/Emphasis';
import { ArrowPoint } from '../components/ArrowPoint';
import { PurchaseButton } from '../components/PurchaseButton';

export default function CurrentReality() {
  const { palette } = useTheme();

  const Divider = styled('div')({
    borderBottom: `1px solid ${palette.secondary.light}`,
    margin: '20px 0',
  });

  return (
    <Box sx={{
      padding: '30px',
      paddingTop: '50px',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '20px',
      backgroundColor: palette.primary.main,
    }}
    >
      <Box sx={{ maxWidth: 800, textAlign: 'center', color: darken(palette.success.dark, 0.8) }}>
        <Typography variant="h4" color={palette.secondary.main} mb={5}>
          Talvez,
          {' '}
          <Emphasis>essa seja</Emphasis>
          {' '}
          a sua realidade atual:
        </Typography>
        <Grid
          sx={{ textAlign: 'left' }}
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <ArrowPoint text="Sente insegurança para abordar o potencial cliente." />
          <ArrowPoint text="Não tem confiança no seu trabalho e sempre cobra mais barato." />
          <ArrowPoint text="Não domina vendas e não sabe como apresentar uma proposta de valor." />
          <ArrowPoint text="Tem dúvidas de como prospectar e fechar contratos fixos." />
          <ArrowPoint text="Posta conteúdos nas redes sociais, mas só atrai copywriter." />
        </Grid>
        <Typography mt={5} variant="h5" color={palette.secondary.main}>
          Isso acontece porque…
          <br />
          Até hoje você não recebeu o passo a passo para encontrar clientes que
          <Emphasis> valorizam </Emphasis>
          o seu trabalho.
        </Typography>
        <Divider />
        <Typography mt={5} variant="h5" fontWeight="bold">
          Há 3 anos eu era igual a você.
        </Typography>
        <Typography mt={2} variant="h6">
          Pulava de curso em curso de copywriting.
        </Typography>
        <Typography mt={2} variant="h6">
          <span style={{ fontWeight: 'bold' }}>
            Minha escrita ficava cada vez melhor, enquanto meu bolso ficava
            cada vez mais vazio.
          </span>
          {' '}
          O que faltava?
        </Typography>
        <Typography mt={5} variant="h5" fontWeight="bold" color={palette.secondary.main}>
          Aprender a vender.
        </Typography>
        <Typography mt={2} variant="h6">
          Quando eu dominei essa habilidade, fechei com mais de 100 clientes que
          me valorizavam e entendiam o valor do meu trabalho.
        </Typography>
        <Typography mt={5} variant="h5">
          Vender ficou fácil e prospectar deixou de ser uma tarefa chata,
          <Emphasis>
            {' '}
            porque
            agora eu alcanço clientes qualificados e que estão
            dispostos a pagar um valor justo
            {' '}
          </Emphasis>
          {' '}
          pelo meu trabalho.
        </Typography>
        <PurchaseButton text="Garantir a minha vaga" />
        <Divider sx={{ marginTop: 8 }} />
        <Typography variant="h4" color={palette.secondary.main} mb={5} mt={8}>
          Para quem é o
          {' '}
          <Emphasis>Projeto Prospecção Ártemis?</Emphasis>
        </Typography>
        <Typography mt={5} mb={4} variant="h5">
          É para
          {' '}
          <span style={{ fontWeight: 'bold' }}> copywriters e profissionais do digital </span>
          que:
        </Typography>
        <Grid
          sx={{ textAlign: 'left' }}
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <ArrowPoint text="Querem prospectar com leveza e estratégia para fechar contratos com clientes que pagam um preço justo, sem reclamar." />
          <ArrowPoint text="Desejam sair de agências de marketing que pagam salário de júnior e fazem você trabalhar como sênior. " />
          <ArrowPoint text="Querem transformar suas redes sociais em um ímã de clientes, sem precisar aparecer. " />
          <ArrowPoint text="Desejam fechar contratos recorrentes que dão segurança e estabilidade a longo prazo." />
        </Grid>
      </Box>
    </Box>
  );
}
