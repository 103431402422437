import {
  Paper, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';

export default function BenefitCard({ renderIcon, title }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper
      sx={{
        backgroundColor: '#242424',
        borderRadius: 5,
        padding: '10px',
        paddingTop: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: 'inset 0px 0px 80px 10px rgba(12,12,12,1);',
        minHeight: isMobile ? 160 : 135,
        maxWidth: isMobile ? 150 : 220,
        // width: isMobile ? '100%' : '300px',
      }}
    >
      {renderIcon({
        sx: {
          color: theme.palette.secondary.light,
          fontSize: 80,
          alignSelf: 'center',
          backgroundColor: '#252525',
          borderRadius: '50%',
          padding: '15px',
          marginTop: '-80px',
          boxShadow: '0.3em 0.3em 1em rgba(0,0,0,0.4)',
        },
      })}
      <Typography textAlign="center" alignSelf="center" marginTop="5px" color={theme.palette.primary.light} fontSize={isMobile ? '10pt' : '12pt'}>
        {title}
      </Typography>
    </Paper>
  );
}
