import React from 'react';
import { Button } from '@mui/material';

export function PurchaseButton({ text, style, link }) {
  return (
    <Button
      variant="contained"
      sx={{
        marginTop: 5,
        borderRadius: 30,
        padding: '0 30px',
        height: 70,
        fontSize: '16pt',
        color: '#f1f1f1',
        background: 'linear-gradient(90deg, rgba(166,124,0,1) 0%, rgba(189,160,74,1) 100%);',
        fontWeight: 'bold',
        fontFamily: 'Poppins, sans-serif',
        ...style,
      }}
      onClick={() => window.open(link ?? 'https://pay.kiwify.com.br/fB4CYKK', '_blank')}
    >
      {text || 'Quero ganhar clientes'}
    </Button>
  );
}
