import {
  Box, Grid, Paper, Typography, styled, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';
import { Emphasis } from '../components/Emphasis';
import { ContentCard } from '../components/ContentCard';
import { PurchaseButton } from '../components/PurchaseButton';
import { ArrowPoint } from '../components/ArrowPoint';
import guaranteeImg from '../assets/guarantee.png';

export default function FeaturedContent() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const Divider = styled('div')({
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    margin: '50px 0',
    width: '100%',
  });

  return (
    <Box sx={{
      padding: '15px', paddingTop: '0', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center',
    }}
    >
      <Box sx={{ maxWidth: 1300, textAlign: 'center' }}>
        <Typography variant="h4" color="secondary">
          Confira
          <Emphasis> tudo o que você vai aprender</Emphasis>
        </Typography>
        <Grid container sx={{ padding: '20px' }} spacing={isMobile ? 1 : 5} mt={2}>
          <Grid item xs={12} md={6}>
            <ContentCard number="01" title="Mentalidade">
              Entenda como forjar a mente de um copywriter profissional que prospecta qualquer
              cliente com confiança e não tem medo de vender o seu próprio serviço.
            </ContentCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContentCard number="02" title="Prospecção">
              Descubra os nichos mais rentáveis, onde encontrar
              clientes que pagam um valor justo e qual melhor abordagem
              utilizar para não parecer um vendedor chato. Você também vai aprender a
              criar conteúdo com o método ARC para atrair clientes e não somente copywriters.
            </ContentCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContentCard number="03" title="Vendas">
              Aprenda a montar uma proposta de valor e não de preço e deixe de ser ignorado
              pelo cliente após enviar a sua proposta comercial. Além disso, você vai descobrir
              como estruturar sua proposta comercial, fazer um pitch de vendas e contornar
              qualquer objeção.
            </ContentCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContentCard number="04" title="Fidelização">
              Melhor do que viver atrás de novos clientes, é
              <b> eternizar os melhores. </b>
              No último módulo, você vai aprender como conquistar
              <b> clientes fiéis que trazem previsibilidade. </b>
              Além de entender como estruturar processos como copywriter.
            </ContentCard>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Typography variant="h3" textAlign="center" color="secondary" mb={5}>
        E olha só os
        <Emphasis> bônus que preparei pra você:</Emphasis>
      </Typography>
      <Grid mb={5}>
        <Grid
          sx={{ textAlign: 'left' }}
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <ArrowPoint text="Mentoria Coletiva para tirar suas dúvidas." />
          <ArrowPoint text="Masterclass: como melhorar a sua oratória para qualquer reunião que você fizer com seu potencial cliente? (com Gabrielle Moraes, Mentora de Oratória)." />
          <ArrowPoint text="Masterclass: como construir uma carreira de copy no mundo corporativo? (com Pedro Yamamoto, Copywriter Sênior da Hotmart)." />
          <ArrowPoint text="Masterclass: como criar conexões emocionais e entregar valor para o cliente (com Paola Brescianini, especialista em Branding e Marca Pessoal)." />
          <ArrowPoint text="Acesso aos meus modelos de mensagens de prospecção, follow up e desistência." />
          <ArrowPoint text="Trello de acompanhamento do processo do seu cliente (CRM)." />
          <ArrowPoint text="Guia completo para contornar as maiores objeções dos clientes." />
          <ArrowPoint text="Comunidade para alunos no Whatsapp." />
          <ArrowPoint text="PDF com modelo de proposta comercial irrecusável." />
        </Grid>
      </Grid>
      <Typography variant="h4" textAlign="center" color="secondary" mb={5} sx={{ maxWidth: '800px' }}>
        Você está prestes a conquistar clientes que te valorizam e pagam o que você merece
      </Typography>
      <Grid>
        <Paper
          variant="outlined"
          sx={{
            padding: isMobile ? '30px 25px' : '35px 50px', borderRadius: 8, borderWidth: 2, borderColor: theme.palette.secondary.main,
          }}
          elevation={5}
        >
          <Typography fontSize={isMobile ? '14pt' : '16pt'} lineHeight={isMobile ? 1.6 : 2}>
            <Emphasis>Módulo 1: </Emphasis>
            Mentalidade do Copy Profissional
          </Typography>
          <Typography fontSize={isMobile ? '14pt' : '16pt'} lineHeight={isMobile ? 1.6 : 2}>
            <Emphasis>Módulo 2: </Emphasis>
            Método Ártemis de Prospecção
          </Typography>
          <Typography fontSize={isMobile ? '14pt' : '16pt'} lineHeight={isMobile ? 1.6 : 2}>
            <Emphasis>Módulo 3: </Emphasis>
            Venda Valor e não Preço
          </Typography>
          <Typography fontSize={isMobile ? '14pt' : '16pt'} lineHeight={isMobile ? 1.6 : 2}>
            <Emphasis>Módulo 4: </Emphasis>
            Fidelização de Clientes
          </Typography>
          <Typography fontSize={isMobile ? '25pt' : '30pt'} fontWeight="bold" color="secondary" textAlign="center">+</Typography>
          <Typography fontSize={isMobile ? '14pt' : '16pt'} lineHeight={isMobile ? 1.6 : 2}>
            <Emphasis>Mentoria Coletiva </Emphasis>
            para tirar suas dúvidas.
          </Typography>
          <Typography fontSize={isMobile ? '14pt' : '16pt'} lineHeight={isMobile ? 1.6 : 2}>
            <Emphasis>Aula Bônus: </Emphasis>
            Melhore sua oratória e faça reuniões com confiança.
          </Typography>
          <Typography fontSize={isMobile ? '14pt' : '16pt'} lineHeight={isMobile ? 1.6 : 2}>
            <Emphasis>Aula Bônus: </Emphasis>
            Carreira de Copy no mundo corporativo.
          </Typography>
          <Typography fontSize={isMobile ? '14pt' : '16pt'} lineHeight={isMobile ? 1.6 : 2}>
            <Emphasis>Aula Bônus: </Emphasis>
            Como criar conexões emocionais e entregar valor para o cliente.
          </Typography>
          <Typography fontSize={isMobile ? '14pt' : '16pt'} lineHeight={isMobile ? 1.6 : 2}>
            <Emphasis>Acesso aos meus modelos </Emphasis>
            de mensagens de prospecção.
          </Typography>
          <Typography fontSize={isMobile ? '14pt' : '16pt'} lineHeight={isMobile ? 1.6 : 2}>
            <Emphasis>Trello de acompanhamento </Emphasis>
            do processo do seu cliente (CRM).
          </Typography>
          <Typography fontSize={isMobile ? '14pt' : '16pt'} lineHeight={isMobile ? 1.6 : 2}>
            <Emphasis>Guia completo para contornar </Emphasis>
            as maiores objeções dos clientes.
          </Typography>
          <Typography fontSize={isMobile ? '14pt' : '16pt'} lineHeight={isMobile ? 1.6 : 2}>
            Modelo de
            <Emphasis> proposta comercial irrecusável </Emphasis>
          </Typography>
          <Box textAlign="center">
            <Typography variant="h5" mt={5} color={theme.palette.secondary.dark} sx={{ textDecoration: 'line-through' }}>
              De R$ 997,00
            </Typography>
            <Typography variant="h6" color={theme.palette.secondary.dark}>
              por
            </Typography>
            <Typography variant="h3" color={theme.palette.secondary.light} fontWeight="bold">
              12x de R$ 49,90
            </Typography>
            <Typography variant="h5" mt={1} color={theme.palette.success.dark}>
              Ou R$ 497,00
            </Typography>
            <PurchaseButton text="Garantir a minha vaga" />
          </Box>
        </Paper>
      </Grid>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" maxWidth={700} padding={2} textAlign="center" mt={5}>
        <Box mb={3}>
          <Typography fontSize={isMobile ? '30pt' : '32pt'} color={theme.palette.secondary.main} fontWeight="bold">Risco zero!</Typography>
          <Typography fontSize={isMobile ? '16pt' : '20pt'} color={theme.palette.primary.contrastText} mt={2}>
            Caso você não queira mais
            <Emphasis> prospectar clientes de alto valor, </Emphasis>
            poderá entrarem contato com o suporte e
            solicitar a devolução de 100% do valor investido.
          </Typography>
        </Box>
        <img src={guaranteeImg} alt="Garantia de 7 dias" width={300} />
      </Box>
    </Box>
  );
}
