import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

export function ArrowPoint({ text, lightArrow }) {
  const { palette } = useTheme();

  return (
    <Grid container item alignItems="center" justifyContent="flex-start" sx={{ minHeight: '80px' }} flexWrap="nowrap" spacing={2}>
      <Grid item>
        <ArrowCircleRightOutlinedIcon
          sx={{ color: lightArrow ? palette.secondary.contrastText : palette.secondary.main }}
          fontSize="large"
        />
      </Grid>
      <Grid item sx={{ maxWidth: '70vw' }}>
        <Typography fontSize="14pt">
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}
