import {
  Box, Paper, Typography, darken, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';

export function ContentCard({ number, title, children }) {
  const theme = useTheme();
  const { palette } = theme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '30px',
        // background: 'linear-gradient(115deg, rgba(166,124,0,1) 0%, rgba(200,174,99,1) 100%)',
        background: 'linear-gradient(-100deg, rgba(215,205,176,0.4) 0%, rgba(209,191,137,0.4) 25%, rgba(203,182,118,0.1) 60%, rgba(166,124,0,0.18) 100%)',
        flexWrap: 'nowrap',
        minHeight: isMobile ? '340px' : '380px',
        marginBottom: '20px',
      }}
      elevation={5}
    >
      <Typography
        variant="h3"
        textAlign="center"
        color={darken(palette.secondary.main, 0.4)}
        sx={{
          minWidth: 50,
        }}
      >
        {number}
      </Typography>
      <Box sx={{
        width: '20%',
        backgroundColor: darken(palette.secondary.main, 0.1),
        height: '2px',
        margin: '10px 0 20px 0',
      }}
      />
      <Box sx={{ textAlign: 'center' }}>
        <Typography fontSize="24pt" color={darken(palette.secondary.main, 0.25)} fontWeight="bold" mb={2} textAlign="center">
          {title}
        </Typography>
        <Typography color={darken(palette.secondary.dark, 0.5)} fontSize={isMobile ? '14pt' : '16pt'}>
          {children}
        </Typography>
      </Box>
    </Paper>
  );
}
